(function () {
  'use strict'

  /**
   * @ngdoc filter
   * @name trek.filter:position
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('trek')
    .filter('position', position)

  function position() {
    return function (input) {
      if (input === 'captain') {
        return 'Captain'
      }
      if (input === 'crew') {
        return 'Crew'
      }
      return '?'
    }
  }
}())
